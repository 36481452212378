@import "print.css";
@import "prism.css";
@import "base.css";

@tailwind components;
@tailwind utilities;

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  -webkit-animation: none !important;
  animation: none !important;
}
.ck-editor__editable_inline {
  min-height: 150px; /* Ajustez cette valeur pour correspondre à environ 6 lignes de texte */
}
.custom-textfield .MuiInputBase-root {
  height: 35px; /* Ajustez cette valeur selon vos besoins */
  padding: 0;
  /* z-index: 1;
  transform: translate(14px, 10px) scale(1);
  pointer-events: none;*/
} 

.new-custom-label {
    z-index: 1;
    transform: translate(14px, 10px) scale(1);
    pointer-events: none;
}  
 
 

html {
  font-size: 62.5%;
  font-family: Muli, Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #121212;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  margin: 0;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role="button"] {
  text-decoration: none;
}

[role="tooltip"] {
  z-index: 999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 12px;
    height: 12px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

@-webkit-keyframes autofill {
  0%,
  100% {
    background: transparent;
  }
}

@keyframes autofill {
  0%,
  100% {
    background: transparent;
  }
}
input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-name: autofill !important;
  animation-name: autofill !important;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  box-shadow: 0 0 0 100px transparent inset !important;
  -webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

/*
  START CUSTOM CSS
*/

/* Light blue hover color of the menuList */
.MuiMenu-paper.MuiPopover-paper > ul > li.Mui-selected,
.MuiAutocomplete-popper
  .MuiAutocomplete-option[aria-selected="true"] {
  background-color: #039be5;
  color: #fff;
}

/* Dark blue hover color of the menuList */
.MuiMenu-paper.MuiPopover-paper > ul > li:hover,
.MuiButtonBase-root:hover .MuiSelect-select,
.MuiAutocomplete-listbox > ul > li:hover {
  color: #fff;
  background-color: #373d4b;
}

/* Label color */
.MuiButtonBase-root:hover label {
  color: #fff;
}

/* Input underline color */
.MuiButtonBase-root:hover .MuiInput-underline:before {
  border-bottom: 2px solid #fff !important;
}

/* Text + icons color of the autocomplete select */
.MuiButtonBase-root:hover .MuiAutocomplete-inputRoot input,
.MuiButtonBase-root:hover .MuiAutocomplete-endAdornment button {
  color: #fff;
}

/* Arrow down icon color of the menuList */
.MuiButtonBase-root:hover .MuiSelect-icon {
  color: #fff;
}

/* Color of the calendor icon/button */
.MuiButtonBase-root:hover .MuiInputAdornment-positionEnd button {
  color: #fff;
}

/* Color of the calendor icon/button after choosing */
.MuiButtonBase-root:hover .MuiInputBase-inputAdornedStart,
.MuiButtonBase-root:hover .MuiIconButton-label {
  color: #fff !important;
}
/* Range input in 'facture' */
.MuiButtonBase-root:hover > div div,
.MuiButtonBase-root:hover .MuiSlider-root {
  color: #fff !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"].no-arrows {
  -moz-appearance: textfield;
}

/*
  END CUSTOM CSS
*/
